#output {
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  position: relative;
  overflow: auto;
}

#output svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.button-exchange {
  position: absolute;
  left: 5px;
  bottom: 60px;
  font-size: 25px;
  border: none;
  color: white;
  background: #aaaaaa;
  border-radius: 5px;
  cursor: pointer;
}
.evidence-header {
  background: #ECF4FF;
  border: 1px solid #DDDDDD;
  margin: -48px -48px 20px -48px;
  padding: 30px;
}

.evidence-header-title {
  font-weight: bold;
  font-size: 30px;
  margin-left: 20px;
}

.evidence-section {
  font-size: 14px;
  font-weight: normal;
  margin-top: 30px;
}

.evidence-card {
  background: #F0FFE9;
  border: 1px solid #74DE62;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  font-size: 20px;
  color: #08A20E;
}

.evidence-card p {
  margin: 0px;
  font-size: 14px;
}

.evidence-card-red {
  background: #FFF4F4;
  border: 1px solid #FC9C9E;
  color: #D64264;
}

.gray-text {
  color: #CCC;
}

.filters-container {
  margin: 20px 0px;
}

.evidence-filter {
  margin-right: 15px;
}

.rule-name {
  font-size: 14px;
  font-weight: normal;
  color: #727272;
  padding-left: 40px;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

/*trigger:hover {
  color: #1890ff;
}*/

.logo {
  height: 100px;
  background-image: url('../../../../assets/images/taskit_logo_blanco_web.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 16px;
}

.user-info {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.user-info__text-container {
  margin-right: 5px;
}
.user-info__text {
  display: block;
  line-height: 100%;
}

.user-info__text--primary {
  font-weight: bold;
  font-size: 14px;
}

.user-info__text--secundary {
  font-size: 12px;
}

.header-content {
  display: flex;
  justify-content: space-between;
}

.header-section {
  flex: 1;
}

.header-section__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  font-weight: bold;
}

.header-section__user-info {
  justify-content: flex-end;
}

.header-section__menu-icon {
  font-size: 30px;
  margin-right: 10px;
  color: #999999;
}

.option-icon {
  font-size: 1.3em;
  margin-right: 10px;
}

.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover {
  font-weight: bold;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  font-weight: bold;
}

@media screen
  and (max-width: 992px) {
  .fixed-sider {
    position: absolute;
    height: 100%;
    z-index: 2;
  }

  .fixed-sider > .ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: 15px;
    right: -36px;
    font-size: 25px;
    background: #001529;
    color: #A9A9A9;
  }

  .fixed-sider.ant-layout-sider-collapsed > .ant-layout-sider-zero-width-trigger {
    right: -50px;
    background: none;
  }
}
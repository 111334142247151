.text-bold {
    font-weight: bold;
}

.execution-section {
    width: 100%;
    padding-bottom: 10px;
}

.execution-title {
    font-weight: bold;
    font-size: 20px;
    word-break: break-word;
}

.execution-card-timeline {
    color: #5f5f5f;
    border: none;
    padding: 20px 20px 0px 20px;
}

.execution-section-info {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid lightgray;
}

.no-image-card {
    border: 1px solid lightgray;
    width: 100%;
    height: 200px;
    padding-top: 60px;
    color: lightgray;
}

.disabled-link {
    cursor: default;
}

.disabled-link .execution-card-timeline{
    background-color: #f1f1f1;
}

.text-info-bold {
    margin-right: 15px;
}

.execution-divider {
    border-right: 1px solid lightgray;
    margin: 0px 20px;
}
/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-06 04:42:36 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-08-23 13:50:53
 */

 .loadingContainer {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
 }
 
 .loadingText {
  margin-right: 10px;
 }
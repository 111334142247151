.text-bold {
    font-weight: bold;
}

.execution-section {
    width: 100%;
    padding-bottom: 30px;
}

.execution-card {
    margin-bottom: 15px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    background: white;
    color: #414141;
}

.execution-card > .ant-card-body {
    padding: 0px;
}

.rating-section {
    height: 20px;
    font-size: 12.5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
}

.rating-section__ribbon {
    background: #2F79DB;
    border: 1px solid #2F79DB;
    border-radius: 4px 0px 0px 0px;
    padding: 1px 6px;
    color: white;
    width: fit-content;
}

.rating-section__ribbon--yellow {
    background: #D0AD30;
    border: 1px solid #D0AD30;
}

.rating-section__ribbon-rate {
    background: #CBE2FF;
    border: 1px solid #CBE2FF;
    padding: 1px 6px;
    color: #2368C3;
    width: fit-content;
}

.execution-section-info {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid lightgray;
}

.no-image-card {
    border: 1px solid lightgray;
    width: 100%;
    height: 200px;
    padding-top: 60px;
    color: lightgray;
}

.disabled-link {
    cursor: default;
}

.disabled-link .execution-card{
    background-color: #f1f1f1;
}

.text-info-bold {
    margin-right: 15px;
}

.info-divider {
    border-right: 1px solid #DDDDDD;
    margin: 0px 10px;
}

.card__image {
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.execution-card__task-section {
    border-top: 1px solid #DDDDDD;
    padding: 8px 20px;
}

.execution-card__info-section {
    padding: 8px 20px 0px 20px
}

.execution-card__avatar {
    position: relative;
    top: -5px;
    margin-right: 5px;
}

.info-wrapper { width: 100%; }

.info-section__title {
    width: 75%;
    height: 30px;
    float: left;
    font-weight: 600;
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.info-section__subtitle {
    width: 75%;
    height: 30px;
    float: left;
    font-weight: 200;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #727272;
}
.info-section__data-left {
    width: 75%;
    height: 30px;
    margin-top: 10px;
    float: left;
}

.info-section__data-right {
    height: 70px;
    overflow: hidden;
    float: right;
    width: calc(25% - 10px);
}

.data-left__section {
    display: inline;
}

.data-left__section--absolute {
    position: absolute;
}

.data-left__inline-container {
    position: relative;
    margin-right: 5px;
}

.data-right__section {
    float: right;
    width: 100%;
    text-align: right;
}

.data-right__section--primary {
    font-size: 18px;
    color: #414141;
}

.data-right__section--secondary {
    font-size: 14px;
    color: #727272;
}

.data-right__section--purple {
    font-size: 12px;
    color: #6E38FF;
}

.info-section__label {
    font-size: 12px;
    color: #A3A3A3;
}

.info-section__label--absolute {
    position: absolute;
    top: -15px;
    left: 0px;
}

.info-section__text {
    font-size: 14px;
    color: #727272;
}

@media (max-width: 1200px) and (min-width: 992px), (max-width: 768px) {
    .info-section__data-right {
      margin-top: 10px;
      height: 70px;
    }

    .info-divider--hide {
        display: none;
    }
    
    .info-section__data-left { margin-top: 0px; }
    
    .info-section__title { width: 100% }
    .info-section__subtitle { width: 100% }
    
    .data-left__section { display: block; width: 100%; height: 30px; margin-top: 10px; }
    .data-right__section { display: inline; margin-right: 10px; }
  }

@media (max-width: 576px) {
    .execution-card__info-section {
        height: 140px;
        overflow-y: hidden;
    }

    .info-section__data-right {
        margin-top: 10px;
        width: 100%;
        height: 14px;
    }

    .info-section__text {
        font-size: 12px;
    }
  
    .info-section__title {
        width: 100%;
    }
    .info-section__subtitle {
        width: 100%;
    }
    .info-section__data-left {
        width: 100%;
        margin-top: 0px;
    }
    
    .data-left__section {
        display: block;
        width: 100%;
        height: 30px;
        margin-top: 10px;
    }
    .data-right__section {
        display: inline;
        margin-right: 10px;
        width: fit-content;
        float: left;
    }

    .data-right__section--primary {
        font-size: 12px;
        margin-right: 5px;
    }

    .data-right__section--secondary {
        font-size: 12px;
    }
    
    .data-right__section--purple {
        font-size: 12px;
    }
}
/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-24 10:29:19
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-25 10:53:46
 */
.fondo {
  flex: 100%;
  background: rgb(44, 172, 203);
  background: linear-gradient(
    90deg,
    rgba(44, 172, 203, 1) 0%,
    rgba(64, 181, 203, 1) 28%,
    rgba(15, 228, 233, 1) 100%
  );
}

.letra {
  font-weight: bold;
  font-size: 22px;
  font-family: arial, helvetica, roboto;
}

.style-tag {
  border-radius: 9px;
  margin-top: -1px;
  margin-right: 1px;
  float: left;
}

.body {
  flex: 100%;
  background: #f2f2f2;
}

.site-card-border-less-wrapper {
  padding: 1  px;
}

.style-p {
  font-size: 28;
  font-weight: 'bold';
}
.task-type__icon {
  font-size: 16px;
  color: #D5D5D5;
  margin-right: 10px;
  margin-left: 10px;
}

.task-type__icon--blue {
  color: #72ADFF;
}

.task-type__icon_image {
  font-size: 100px;
  color: #D5D5D5;
  margin-right: 10px;
  margin-left: 10px;
}

.task-type__icon_image--blue {
  color: #72ADFF;
}

.task-type__icon--green {
  color: #3DC15F;
}
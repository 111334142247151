/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-24 17:33:11 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-05-21 15:56:35
 */
.actionButton {
  margin: 3px;
}

.textChange {
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none
  
}
.textChange:hover {
  color: #dc0030;
  transition: all .2s ease-out;
  font-size: 14.3px;
  text-decoration: none;
}

.textChange:active {
  text-decoration: none;
}

.inputContainer {
  display: inline-flex;
  align-items: center;
}

.inputContainer__span {
  padding-right: 5px;
  color: red;
  font-size: 1.1em;
}

.buttonGhost {
  border: 0;
  background-color: transparent;
}

.buttonGhost:hover {
  background-color: transparent;
}

.buttonGhost:active {
  background-color: transparent;
}

.buttonGhost:focus {
  background-color: transparent;
}

.select-data {
  width: 100%;
  padding-right: 10px;
}

.cards-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 39px;
}

.cards-box__card {
  font-size: 30px;
  width: 40px;
  height: 26px;
  opacity: 0.3;
}

.cards-box__card--active {
  opacity: 1;
}

.visa-logo {
  background-image: url('../../../assets/images/visa-logo.png');
}

.mastercard-logo {
  background-image: url('../../../assets/images/mastercard-logo.png');
}

.ae-logo {
  background-image: url('../../../assets/images/ae-logo.png');
}

.payment-source-box {
  display: flex;
}

.payment-source-box h2 {
  margin-right: 15px;
}

.change-payment-method-checkbox {
  font-size: 1.3em;
}

.rule-form-item {
  margin-bottom: 20px;
}
.dashboard-header {
  background: #ECF4FF;
  border: 1px solid #DDDDDD;
  padding: 25px;
}

.dashboard-header__title {
  font-weight: bold;
  font-size: 25px;
}

.actions-container {
  padding: 10px;
}
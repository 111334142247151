/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 10:29:38
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-09-02 16:51:12
 */
body,
html {
  height: 100vh;
}

/* Flex helpers */
.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

/* Margin and padding helpers */
.margin-1 {
  margin: 1rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-half-rem {
  margin: 0.5rem;
}

.margin-top-half-rem {
  margin-top: 0.5rem;
}

.margin-bottom-half-rem {
  margin-bottom: 0.5rem;
}

.margin-left-half-rem {
  margin-left: 0.5rem;
}

.margin-right-half-rem {
  margin-right: 0.5rem;
}

/* Size helpers */
.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.facebook-background {
  background: #4f52b3;
}

.google-background {
  background: #c62828;
}

/* Text helpers */

.text-center {
  text-align: center;
}

.align-middle {
  align-items: center;
}

.link {
  color: #353535;
}

.link--padding {
  padding: 5px 0;
}

.headerContainer {
  margin-bottom: 10px;
}

.stepsContainer {
  padding-bottom: 15px;
  border-bottom: 1px solid #E7E7E7
}
.lineDividerSteps {
  border-bottom: 1px solid #E7E7E7
}

.stepsContainer .stepsBox {
  width: 650px;
  margin: 0 auto;
}

.stepsContainer .selectBranch {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
}

.stepsContainer .textBranch {
  margin: 0;
  margin-right: 10px;
}

.filtersRow {
  align-items: center;
}

.filter-box {
  padding: 10px 0px 10px 0px;
}

.filterIcon {
  color: 'red';
  opacity: 0.8;
}

.deleteFilterButton {
  margin-left: 10px;
}

.button-create-cell {
  position: absolute;
  right: 0;
  bottom: 0;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

.filter-section {
  border-bottom: 1px solid #CFCFCF;
  margin: 0rem 0rem 1rem 0rem;
}

.filter-select {
  width: 100%;
}

.filter-item {
  padding: 0rem 0rem 1rem 0rem;
}

.filter-item > p {
  font-size: 1rem;
  font-weight: bold;
  color: #3E95E5;
  margin-bottom: 0em;
}

.filter-title {
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 1rem;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.preview-image-container .ant-modal-content {
  background-color: transparent;
}

.preview-image-container .ant-modal-close {
  color: white;
}

.preview-image-container .ant-modal {
  max-width: 100%;
}

.clear-filters {
  color: #2684ff;
  font-size: 12px;
  margin-top: 5px;
  cursor: pointer; 
}

.link-button {
  color: #2684ff;
  cursor: pointer; 
}

.tasks-table {
  margin-top: 10px;
}

.tasks-table table {
  font-size: 12px;
}

.modal-header-dark .ant-modal-header {
  background: #4C4C4C;
}

.modal-header-dark .ant-modal-title,
.modal-header-dark .ant-modal-close {
  color: white;
}

.ant-table-body { overflow-x: auto !important}

.filters-drawer-button {
  display: none;
}

@media (max-width: 992px) {
  .filters-drawer-button {
    display: block;
    position: absolute;
    top: -67px;
    left: 30px;
    font-size: 25px;
    color: #A9A9A9;
    z-index: 1;
  }
}

@media (max-width: 992px) {
  .hide-sm {
    display: none;
  }
}

.loading-image {
  background:  url('../assets/images/loader.gif') center no-repeat #fff;
}

.vertical-timeline-element-content {
  color: black;
}

.vertical-timeline-element-title {
  font-size: 1rem;
  font-weight: bold;
}

.vertical-timeline-element-date {
  color: #333;
}

.timeline-white .vertical-timeline-element-content {
  border-top: 3px solid #2196f3;
  padding: 0px;
}

.timeline-pink .vertical-timeline-element-content {
  border-top: 3px solid #e91e63;
}

.timeline-link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.green-border .vertical-timeline-element-icon:before {
  content: '';
  display: block;
  width: 58px;
  height: 58px;
  border: 5px solid #10CC52;
  border-radius: 50%;
  margin: -9px;
  position: absolute;
  top: 0px;
}

.tasks-icon {
  font-size: 18px;
  color: rgb(184, 184, 184);
  margin-right: 10px;
}

.timeline-logs b {
  color: chocolate;
}

td.table__td--gray {
  background: #fafafa;
}

.table__td--border-left {
  border-left: 1px solid #DBDBDB;
}

/* ----------------------------------- Responsive Tables ------------------------------------- */

@media (max-width: 1300px) {
  .responsive-table table,
  .responsive-table tbody,
  .responsive-table tr,
  .responsive-table td {
    display: block;
  }

  .responsive-table table {
    width: 100%;
  }

  .responsive-table thead {
    display: none;
  }

  .responsive-table tr {
    margin-bottom: 50px;
    border: 1px solid #AAA;
  }

  .responsive-table td {
    position: relative;
    text-align: left;
    padding-left: 220px !important;
    border: 1px solid #e8e8e8;
    border-top: none;
    word-break: break-all;
  }

  .responsive-table td:before {
    content: attr(data-label);
    position: absolute;
    white-space: normal;
    word-break: normal;
    top: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    text-align: left;
    padding: 15px 13px;
    font-weight: 600;
    border-right: 1px solid #e8e8e8;
  }
}

@media (max-width: 576px) {
  .responsive-table td {
    position: relative;
    text-align: left;
    padding-left: 120px !important;
    border: 1px solid #e8e8e8;
    border-top: none;
  }

  .responsive-table td:before {
    width: 100px;
  }
}

/* ------------------------------------------------------------------------------------------- */

.leaflet-container {
  height: 300px;
  width: 100%;
  border: 1px solid black;
}

div[disabled]
{
  pointer-events: none;
  opacity: 0.6;
}

/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-19 14:58:36
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-19 20:57:30
 */

.btn-border {
  border-radius: 15px;
  margin: 6px;
}

.btn-effect:hover {
  transform: scale(1.05);
  transition: transform .2s;
}
.tasks-card {
    margin-bottom: 2rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.18);
  }

.tasks-grid {
    margin-bottom: 2rem;
    
    background-color:transparent;
  }
  
.tasks-section {
  padding: 0.5rem 0rem 0.5rem 0rem;
  font-size: 14px;
  color: #727272;
  word-break: break-word;
}

.tasks-section-bordered {
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
}

.tasks-rate-icon {
  color: #BDBDBD;
  font-size: 2rem;
  padding: 0.2rem;
}

.tasks-title {
  font-size: 17px;
  font-weight: bold; 
  color: #414141;
  margin-left: 10px;
}

.task-tag {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10rem;
    vertical-align: top;
}
.react-emoji-picker--wrapper.react-emoji-picker--wrapper__show {
	bottom: -410px;
  width: 360px;
}

.form-section {
  padding: 24px;
}

.form-border {
  border: 1px solid #DDDDDD;
}

.form-border-bottom {
  border-bottom: 1px solid #DDDDDD;
}

.form-border-top {
  border-top: 1px solid #DDDDDD;
}

.form-body {
  padding: 24px;
}

.form-title {
  font-weight: bold;
  font-size: 17px;
  margin: 8px 0px;
}

.form-label {
  font-weight: bold;
  font-size: 14px;
  margin: 8px 0px;
}

.form-label > span {
  font-weight: normal;
  font-size: 13.5px;
  color: #727272;
  margin-left: 8px;
}

.form-description {
  font-weight: normal;
  font-style: italic;
  color: #727272;
  margin-top: 8px;
  margin-left: 24px;
}

.form-radio {
  display: block;
  height: 30px;
  line-height: 30px;
}

.form-button {
  width: 150px;
  margin-right: 15px;
}

.form-input {
  padding-right: 15px;
}

.lightblue-background {
  background-color: #ECF4FF;
}

.gray-background {
  background-color: #FAFAFA;
}

.collapse-icon {
  color: #2684ff;
  font-size: 20px;
  padding: 0px 20px;
}

.color-lightgray {
  color: #d5d5d5;
}

.color-gray {
  color: #A3A3A3;
}

.vertical-border-right {
  border: 1px solid #DBDBDB;
  border-top: none;
  border-bottom: none;
  border-left: none;
}
/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-14 14:41:06 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-19 11:42:33
 */
.login__logo {
  height: 55px;
  margin: 75px 20px;
}

.login__wrapper {
  height: 100%;
}

.login__text {
  text-align: center;
  font-weight: 600;
}

.login__welcome-text {
  font-size: 3em;
  margin-bottom: 0;
}

.login__text--justyfy {
  text-align: justify;
}

.login__box {
  box-sizing: border-box;
  padding: 10px 30px;
  background-color: #ffffff;
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.5);
  border-radius: 4px;

}

.login__box__img {
  display: flex;
  justify-content: center;
}

.login__container {
  margin-top: 70px;
}

.login__form__footer {
  display: flex;
  justify-content: center;
  margin: 0;
}

.login__button-container {
  margin-bottom: 10px;
  padding-top: 20px;
}

.auth-leyend-box {
  width: 100%;
}

.auth-leyend-box-mail {
  color: #2684FF;
}

.auth-leyend-box__icon {
  font-size: 3em;
  display: block;
  margin-right: 5px;
}

.auth-leyend-box__icon--success {
  color: #00c900;
}

.auth-leyend-box__icon--error {
  color: #FF2424;
}

.register {
  width: 100%;
  min-height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  background: url("../../assets/images/background-web_task-it.jpg");
  background-size: cover;
  background-position: center;
}

.register__logo {
  height: 55px;
  margin: 30px 0;
}

.register__title {
  font-size: 8em;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: 0;
}


.register__paragraph {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.3em;
  font-weight: 300;
}

.register__box-form {
  box-sizing: border-box;
  padding: 10px 30px;
  background-color: #ffffff;
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.25);
  border-radius: 1px;
}

.register__title-form {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.register__title-form--margin {
  margin: 0 15px;
}

.register__button {
  background: #2880ec;
  border: none;
  color: #fff;
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.25);
}

.register__button--facebook {
  background: #3b5998;
  margin: 5px;
}

.register__button--google {
  background: #c62828;
  margin: 5px;
}

.register__button:hover {
  background: #2880ec;
  border: none;
  color: #fff;
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.25);
}

.register__button:focus {
  background: #2880ec;
  border: none;
  color: #fff;
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.25);
}

.register__button--facebook:hover {
  background: #3b5998 !important;
  border: none;
  color: #fff;
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.25);
}

.register__button--facebook:focus {
  background: #3b5998 !important;
  border: none;
  color: #fff;
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.25);
}

.register__button--google:hover {
  background: #c62828 !important;
  border: none;
  color: #fff;
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.25);
}

.register__button--google:focus {
  background: #c62828 !important;
  border: none;
  color: #fff;
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.25);
}

.ant-btn-clicked:after {
  border-color: #2880ec;
}

@media screen 
  and (min-width: 1200px) 
  and (max-width: 1600px) {
    .register__title {
      font-size: 8em;
    }
}

@media screen 
  and (max-width: 1200px) {
  .register__title {
    font-size: 7.5em;
  }
}

@media screen
  and (max-width: 992px) {
  .register {
    padding: 50px 0 120px;
  }

  .login {
    padding: 0;
  }
  .register__title {
    text-align: center;
  }
}


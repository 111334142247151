.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  height: 200px;
  color: #ccc;
  width: unset;
}

.card-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #ccc; */
  height: 200px;
  color: #ccc;
  width: unset;
}

.image-icon {
  font-size: 50px;
  padding-bottom: 20px;
}

.disableRow {
  background: #ededed;
}

.rbc-agenda-date-cell, .rbc-agenda-time-cell {
  background-color: #ffffff;
}

.rbc-agenda-date-cell {
  border: 1px solid #DDD;
}

.rbc-agenda-event-cell {
  color: #ffffff;
  cursor: pointer;
}
.contact-info {
  height: 30px;
}

.contact-name {
  color: #2A60B2;
  font-size: 14px;
  font-weight: bold;
  height: 10px;
}

.contact-button {
  font-size: 22px;
  color: #2684FF;
  margin: 10px;
}

.contact-container {
  border: 1px solid #DEDEDE;
  border-left: none;
  border-right: none;
  margin-top: 10px;
}